import React from 'react'
import {translate as _} from '@morawadigital/skynet-framework'
import {Button} from 'react-bootstrap'
import UploadFile from './UploadFile'

class Upload extends React.Component {

    #dom
    #dragCounter

    constructor(props) {

        super(props)

        this.state = {

            dragging: false,
            files: [],

        }

        this.#dragCounter = 0

        this.#dom = {

            container: React.createRef(),

        }

    }

    addFiles(e) {

        if (!e) {

            return

        }

        const files = []

        for (const file of e) {

            files.push(file)

        }

        this.setState({files: [...this.state.files, ...files]})

    }

    handleDragEnter(e) {

        e.preventDefault()
        e.stopPropagation()

        this.#dragCounter++

        e.dataTransfer && e.dataTransfer.items && e.dataTransfer.items.length > 0 && this.setState({dragging: true})

    }

    handleDragLeave(e) {

        e.preventDefault()
        e.stopPropagation()

        this.#dragCounter--

        if (this.#dragCounter > 0) {

            return

        }

        this.setState({dragging: false})

    }

    handleDragOver(e) {

        e.preventDefault()
        e.stopPropagation()

    }

    handleDrop(e) {

        e.preventDefault()
        e.stopPropagation()

        if (e.dataTransfer && e.dataTransfer.files) {

            this.addFiles(e.dataTransfer.files)

            e.dataTransfer.clearData()

        }

        this.setState({dragging: false})

        this.#dragCounter = 0

    }

    selectFile(e) {

        const input = document.createElement('input')
        input.type = 'file'
        input.multiple = true
        input.onchange = e => this.addFiles(e.target.files)

        input.click()

    }

    render() {

        return (

            <div
                className={'upload-container' + (this.state.dragging ? ' dragging' : '')}
                onDragEnter={e => this.handleDragEnter(e)}
                onDragLeave={e => this.handleDragLeave(e)}
                onDragOver={e => this.handleDragOver(e)}
                onDrop={e => this.handleDrop(e)}
                ref={this.#dom.container}
            >

                <div className='text-center my-5'>

                    <div className='mb-3'>{_('Dateien hierher ziehen oder')}</div>

                    <Button variant='secondary' size='sm'
                            onClick={() => this.selectFile()}>{_('Dateien auswählen')}</Button>

                </div>

                {this.state.files.map((e, i) => <UploadFile file={e} key={i}/>)}

            </div>

        )

    }

}

export default Upload