import React from 'react';
import {useHistory} from 'react-router-dom';
import {translate as _} from '@morawadigital/skynet-framework';
import {Dropdown} from 'react-bootstrap';
import Item from './Item';

function Exercise(props) {
    const history = useHistory();
    const {item, index, onDelete} = props;
    const url = item && `${_('/exercise/')}${item.Id}`;

    const renderOptions = (url) => {
        return (
            <>
                <Dropdown.Item as='button' onClick={() => history.push(url)}>
                    {_('Bearbeiten')}
                </Dropdown.Item>
                <Dropdown.Item as='button' onClick={() => onDelete(item)}>
                    {_('Löschen')}
                </Dropdown.Item>
            </>
        )
    }


    return (
        <Item
            index={index}
            item={item}
            name={item && item.Name}
            options={renderOptions(url)}
            url={url}
        />
    );
}

export default Exercise;
