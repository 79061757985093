import {useCallback, useState} from "react";

const useCreateExercise = () => {
    const create = (exercise) => {
        const created = {
            Id: 0,
            Unit: null,
            ExecuteMinutes: 0,
            Category: null,
            ...(exercise || {}),
        }

        if (exercise) {
            created.Category = exercise.Category ? {
                label: exercise.Category.Name,
                value: exercise.Category.Id,
            } : null;
        }

        return created;
    }

    const [exercise, setExercise] = useState(create())


    const resetExercise = useCallback(() => {
        setExercise(create())
    }, [])

    return [exercise, setExercise, create, resetExercise]
}

export default useCreateExercise



