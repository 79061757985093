import {Icon} from "@morawadigital/skynet-framework"

const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
const typeIcons = {
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'file-excel'
}

export const getFileIcon = e => <Icon icon={e in typeIcons ? typeIcons[e] : 'file'} fw regular/>

export const getReadableFileSize = bytes => {
    const thresh = 1000
    const r = 2
    let u = -1

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
    }
    do {
        bytes /= thresh
        ++u
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1)

    return bytes.toFixed(2).replace('.', ',') + ' ' + units[u]
}