import React from 'react'
import {Button} from 'react-bootstrap'
import {Icon} from '@morawadigital/skynet-framework'

export default class Floaties extends React.Component {

    #el

    constructor(props) {

        super(props)

        this.state = {

            mobileButtonsOpen: false,

        }

        this.#el = React.createRef()

    }

    componentDidMount() {

        new IntersectionObserver(([e]) => e.target.classList.toggle('stuck', e.intersectionRatio < 1), {threshold: 1}).observe(this.#el.current)

    }

    renderDesktopButton(button, i) {

        const className = []

        button.className && className.push(button.className)
        button.position && className.push('position-' + button.position)

        return (

            <Button
                className={className.join(' ')}
                disabled={button.disabled}
                key={i}
                onClick={button.onClick}
                type={button.type || 'button'}
                variant={(button.outline ? 'outline-' : '') + (button.variant || 'primary')}
            >

                {button.label}

            </Button>

        )

    }

    renderMobileButton(button, i) {

        return (

            <Button
                className={this.props.buttons.length > 1 ? 'text-' + (button.variant || 'primary') : ''}
                disabled={button.disabled}
                key={i}
                onClick={button.onClick}
                type={button.type || 'button'}
                variant={this.props.buttons.length > 1 ? 'none' : (button.variant || 'primary')}
            >

                <Icon icon={button.icon}/>

            </Button>

        )

    }

    toggleMobileButtons() {

        this.setState({mobileButtonsOpen: !this.state.mobileButtonsOpen})

    }

    render() {

        return (

            <>

                <div className='floaties-desktop' ref={this.#el}>

                    {this.props.buttons && this.props.buttons.map((button, i) => this.renderDesktopButton(button, i))}

                </div>

                <div className={'floaties-mobile' + (this.state.mobileButtonsOpen ? ' open' : '')}>


                    {this.props.buttons && this.props.buttons.length &&

                        <>

                            {this.props.buttons.length > 1 &&
                                <Button variant='primary' onClick={() => this.toggleMobileButtons()}><Icon
                                    icon='ellipsis-v'/></Button>}

                            {this.props.buttons.map((button, i) => this.renderMobileButton(button, i))}

                        </>

                    }

                </div>

            </>

        )

    }

}