import React, {useState, useEffect} from 'react';
import {Card, Form} from 'react-bootstrap';
import {Prompt, withRouter} from 'react-router-dom';
import {Icon, ajx, translate as _} from '@morawadigital/skynet-framework';
import {getSelectValue, getStringValue, isNum} from '../../../util';
import FieldSet from '../../elements/FieldSet';
import useExerciseResults from "../../../hooks/exercise/use-exercise-results";
import useUnsavedChanges from "../../../hooks/use-unsaved-changes";
import {useHistory} from 'react-router-dom';
import Floaties from "../../controls/Floaties";

function NewOrEdit(props) {
    const {match} = props
    const [saving, setSaving] = useState(false)
    const [validated, setValidated] = useState(false)
    const history = useHistory();

    const [fieldDefinition, valueOptions, exercise, setExercise] = useExerciseResults({
        id: match.params.id,
        getId: isNum(match.params.id)
    })

    console.log(exercise)

    const getExerciseId = exercise.Id || match.params.id || 0;

    const [hasUnsavedChanges, setHasUnsavedChanges] = useUnsavedChanges();

    const save = (e) => {
        e.preventDefault()

        if (!e.currentTarget.checkValidity()) {
            setValidated(true)
            return;
        }

        setSaving(true)
        setValidated(true)

        const data = {
            id: exercise.Id,
            name: getStringValue(exercise.Name),
            unit: getStringValue(exercise.Unit),
            executeMinutes: exercise.ExecuteMinutes,
            categoryId: getSelectValue(exercise.Category),
        }

        const success = (e) => {
            setHasUnsavedChanges(false);

            if (!exercise.Id) {
                setExercise({...exercise, Id: e.Id})
            }


            setExercise((prevExercise) => ({
                ...prevExercise,
                Id: isNum(getExerciseId) ? prevExercise.Id : e.Id
            }))

        }

        ajx({
            complete: () => setSaving(false),
            single: true,
            url: 'api/Exercise/AddOrEdit',
            data,
            success,
        })
    }

    const onChangeFieldHandler = (e, f) => {
        setExercise((prevComp) => ({
            ...prevComp,
            [e]: f,
        }))
        setHasUnsavedChanges(f !== null)
    }

    const formDisabled = props.loading || saving;

    useEffect(() => {
        if (exercise.Id !== 0 && !isNum(match.params.id) && !hasUnsavedChanges) {
            history.push(_('/exercise/') + exercise.Id)
        }
    }, [exercise.Id])

    return (
        <>
            <div className="subheader">
                <h1 className="subheader-title">
                    <Icon icon="stopwatch-20" className="subheader-icon"/>{' '}
                    {_('Exercise')}{' '}
                    {exercise && (
                        <span className="fw-300">
                            {exercise?.Name || ''}
                        </span>
                    )}
                </h1>
            </div>
            <Card>
                <fieldset disabled={formDisabled}>
                    <Card.Body>
                        <Form
                            onSubmit={(e) => save(e)}
                            noValidate
                            validated={validated}
                        >
                            <FieldSet
                                disabled={formDisabled}
                                fieldDefinition={fieldDefinition}
                                onChange={onChangeFieldHandler}
                                valueOptions={valueOptions}
                                values={exercise}
                                isNew={!isNum(getExerciseId)}
                            />
                            <Floaties
                                buttons={[{
                                    icon: 'save',
                                    label: _(
                                        'Speichern'
                                    ),
                                    disabled: formDisabled,
                                    type: 'submit',
                                }]}
                            />
                        </Form>

                    </Card.Body>
                </fieldset>
            </Card>
            <Prompt
                message={_(
                    'Sind Sie sicher, dass Sie die Seite verlassen möchten? Ungespeicherte Änderungen gehen verloren.'
                )}
                when={hasUnsavedChanges}
            />
        </>
    )
}

export default withRouter(NewOrEdit)
