import React from 'react'
import {Icon, translate as _} from '@morawadigital/skynet-framework'
import ItemsPage from "./../ItemsPage";
import Event from "../../items/Event";
import {Link} from "react-router-dom";

function Events(props) {

  const renderSubheaderBlock = () => {
    return (
      <Link to={_('/event/neu')} className='btn btn-primary'><Icon icon='plus'/> {_('Neu')}</Link>
    )
  }

  return (
    <ItemsPage
      dataMethod='POST'
      ItemComponent={Event}
      pageIcon='calendar-days'
      pageTitle={_('Events')}
      getDataUrl='api/Event/Get'
      renderSubheaderBlock={renderSubheaderBlock}
      {...props}
    />
  )
}

export default Events