import React from 'react';
import Field from '../controls/Field';

const FieldSet = (props) => {
    return (
        props.fieldDefinition &&
        props.fieldDefinition.map((field, i) => (
            <Field
                disabled={props.disabled}
                field={field}
                key={i}
                onChange={(e, f) => props.onChange(e, f)}
                value={props.values[field.property]}
                valueOptions={props.valueOptions}
                isNew={props.isNew}
            />
        ))
    );
};

export default FieldSet;
