import {translate as _} from '@morawadigital/skynet-framework'
import {FIELD_TYPE_AUTOCOMPLETE, FIELD_TYPE_NUMBER, FIELD_TYPE_TEXTFIELD} from '../../../util/constants'

const fields = [
  {
    object: 'Exercise',
    property: 'Name',
    label: _('Name'),
    isRequired: true,
    __type: FIELD_TYPE_TEXTFIELD
  },
  {
    object: 'Exercise',
    property: 'Unit',
    label: _('Unit'),
    isRequired: true,
    __type: FIELD_TYPE_TEXTFIELD
  },
  {
    object: 'Exercise',
    property: 'ExecuteMinutes',
    label: _('Execute Minutes'),
    isRequired: true,
    __type: FIELD_TYPE_NUMBER
  },
  {
    object: 'Exercise',
    property: 'Category',
    label: _('Category'),
    isRequired: true,
    __type: FIELD_TYPE_AUTOCOMPLETE,
    __optionsKey: 'categories',
  },
]


export const getFields = () => fields