import {useEffect, useState} from "react";
import {ajx} from "@morawadigital/skynet-framework";
import useCreateEvent from "./use-create-event";
import {getFields} from "../../components/pages/Event/fields";

const useEventResults = (props) => {
    const {
        id,
        getId
    } = props

    const [valueOptions] = useState({})
    const fieldDefinition = getFields()

    const [event, setEvent, create, resetCompetition] = useCreateEvent()

    const onLoad = () => {
        ajx(
            {
                options: {method: 'POST'},
                success: (e) => setEvent(create(...e.filter(item => item.Id === parseInt(id)))),
                url: 'api/Event/Get',
                timeout: 400,
                toggleLoading: true,
            },
        )
    }


    useEffect(() => {
        onLoad()

        return () => {
            resetCompetition()
        }
    }, [getId])

    return [fieldDefinition, valueOptions, event, setEvent]
}

export default useEventResults



