import React from 'react'
import CreatableSelect from 'react-select'
import {createOption, isEmpty} from '../../util'

export default class MultiText extends React.Component {

    #delimiters

    constructor(props) {

        super(props)

        this.state = {

            inputValue: '',

        }

        this.#delimiters = ['Enter', 'Tab', ',', ';', ' ']

    }

    addValue() {

        const inputValue = this.state.inputValue

        this.setState({inputValue: ''}, () => !this.props.value.find(e => e.value === inputValue) && this.props.onChange({
            target: {
                name: this.props.name,
                value: [...this.props.value, createOption(inputValue)]
            }
        }))

    }

    handleBlur() {

        this.state.inputValue && this.addValue()

    }

    handleChange(value) {

        this.props.onChange({target: {name: this.props.name, value}})

    }

    handleInputChange(inputValue) {

        this.setState({inputValue})

    }

    handleKeyDown(e) {

        if (this.state.inputValue && this.#delimiters.includes(e.key)) {

            this.addValue()

            e.preventDefault()

        }

    }

    render() {

        const className = ['react-select', 'multi-text-input']

        if (this.props.required && (isEmpty(this.props.value) || this.props.value === '' || (Array.isArray(this.props.value) && this.props.value.length === 0))) {

            className.push('is-invalid')

        } else {

            className.push('is-valid')

        }

        return (

            <CreatableSelect
                className={className.join(' ')}
                classNamePrefix='react-select'
                components={{DropdownIndicator: null}}
                isDisabled={this.props.disabled}
                inputValue={this.state.inputValue}
                isClearable
                isMulti
                menuIsOpen={false}
                onBlur={() => this.handleBlur()}
                onChange={e => this.handleChange(e)}
                onInputChange={e => this.handleInputChange(e)}
                onKeyDown={e => this.handleKeyDown(e)}
                placeholder=''
                value={this.props.value}
            />

        )

    }

}