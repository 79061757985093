import { useState, useEffect } from 'react';

const useUnsavedChanges = () => {
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false)

    const checkUnsavedChanges = (e) => {
        if (hasUnsavedChanges) {
            e.preventDefault()
            return (e.returnValue =
                    'Sind Sie sicher, dass Sie die Seite verlassen möchten? Ungespeicherte Änderungen gehen verloren.'
            )
        }
    }

    useEffect(() => {
        window.addEventListener('beforeunload', checkUnsavedChanges)

        return () => {
            window.removeEventListener('beforeunload', checkUnsavedChanges)
        };
    }, [])

    return [hasUnsavedChanges, setHasUnsavedChanges]
}

export default useUnsavedChanges;
