import {useCallback, useState} from "react";

const useCreateEvent = () => {
    const create = (event) => {
        return {
            Id: 0,
            Name: null,
            StartDate: null,
            EndDate: null,
            DailyStart: null,
            DailyEnd: null,
            ...(event || {}),
        };
    }

    const [event, setEvent] = useState(create())


    const resetEvent = useCallback(() => {
        setEvent(create())
    }, [])

    return [event, setEvent, create, resetEvent]
}

export default useCreateEvent



