import React from 'react'
import { Icon, translate as _ } from '@morawadigital/skynet-framework'
import Upload from '../elements/Upload'

class Import extends React.Component {

    render() {

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='file-import' className='subheader-icon' /> { _( 'Import' ) }

                    </h1>

                </div>

                <Upload />

            </>

        )

    }

}

export default Import