import {Icon, translate as _, formatDateTime} from '@morawadigital/skynet-framework'
import {
    GENDER_FEMALE,
    GENDER_MALE,
    PERSON_REQUEST_STATUS_ACCEPTED,
    PERSON_REQUEST_STATUS_PENDING,
    PERSON_REQUEST_STATUS_REJECTED,
    PLAYER_LICENSE_REQUEST_STATUS_ACCEPTED,
    PLAYER_LICENSE_REQUEST_STATUS_PENDING,
    PLAYER_LICENSE_REQUEST_STATUS_REJECTED,
    PLAYER_LICENSE_STATUS_DELETED,
    PLAYER_LICENSE_STATUS_EXPIRED,
    PLAYER_LICENSE_STATUS_VALID,
    PLAYER_LICENSE_STATUS_WITHDRAWN,
    SIDE_LEFT,
    SIDE_RIGHT,
    TRANSFER_REQUEST_STATUS_ACCEPTED,
    TRANSFER_REQUEST_STATUS_CANCELED,
    TRANSFER_REQUEST_STATUS_PENDING,
    TRANSFER_REQUEST_STATUS_REJECTED
} from './constants'
import {OverlayTrigger, Tooltip} from 'react-bootstrap'

const genders = [

    {value: GENDER_MALE, label: _('Männlich')},
    {value: GENDER_FEMALE, label: _('Weiblich')},

]

const months = [

    {value: 1, label: _('Jänner')},
    {value: 2, label: _('Februar')},
    {value: 3, label: _('März')},
    {value: 4, label: _('April')},
    {value: 5, label: _('Mai')},
    {value: 6, label: _('Juni')},
    {value: 7, label: _('Juli')},
    {value: 8, label: _('August')},
    {value: 9, label: _('September')},
    {value: 10, label: _('Oktober')},
    {value: 11, label: _('November')},
    {value: 12, label: _('Dezember')},

]

const personRequestStatuses = [

    {value: PERSON_REQUEST_STATUS_PENDING, label: _('Offen')},
    {value: PERSON_REQUEST_STATUS_ACCEPTED, label: _('Akzeptiert')},
    {value: PERSON_REQUEST_STATUS_REJECTED, label: _('Abgelehnt')},

]

const playerLicenseRequestStatuses = [

    {value: PLAYER_LICENSE_REQUEST_STATUS_PENDING, label: _('Offen')},
    {value: PLAYER_LICENSE_REQUEST_STATUS_ACCEPTED, label: _('Akzeptiert')},
    {value: PLAYER_LICENSE_REQUEST_STATUS_REJECTED, label: _('Abgelehnt')},

]

const playerLicenseStatuses = [

    {value: PLAYER_LICENSE_STATUS_VALID, label: _('Gültig')},
    {value: PLAYER_LICENSE_STATUS_DELETED, label: _('Gelöscht')},
    {value: PLAYER_LICENSE_STATUS_WITHDRAWN, label: _('Zurückgezogen')},
    {value: PLAYER_LICENSE_STATUS_EXPIRED, label: _('Abgelaufen')},

]

const sides = [

    {value: SIDE_LEFT, label: _('Links')},
    {value: SIDE_RIGHT, label: _('Rechts')},

]

const transferRequestStatuses = [

    {value: TRANSFER_REQUEST_STATUS_PENDING, label: _('Offen')},
    {value: TRANSFER_REQUEST_STATUS_ACCEPTED, label: _('Akzeptiert')},
    {value: TRANSFER_REQUEST_STATUS_REJECTED, label: _('Abgelehnt')},
    {value: TRANSFER_REQUEST_STATUS_CANCELED, label: _('Storniert')},

]

export const convertContexts = persons => {

    const contexts = []

    persons.forEach(person => {

        person.Clubs && person.Clubs.forEach(club => contexts.push({
            value: 'p-' + person.Id + '_c-' + club.Id,
            label: club.Name + ' (' + getFullName(person) + ')',
            club: {id: club.Id, name: club.Name, shortName: club.Shortname},
            person: {id: person.Id, firstName: person.FirstName, lastName: person.LastName}
        }))
        person.Federations && person.Federations.forEach(federation => contexts.push({
            value: 'p-' + person.Id + '_f-' + federation.Id,
            label: federation.Name + ' (' + getFullName(person) + ')',
            federation: {id: federation.Id, name: federation.Name, shortName: federation.Shortname},
            person: {id: person.Id, firstName: person.FirstName, lastName: person.LastName}
        }))

    })

    return contexts

}

export const createOption = e => ({value: e, label: e})

export const createOptions = (options = [], valueKey = 'Id', labelKey = 'Name') => options.map(option => ({
    label: option[labelKey],
    value: option[valueKey]
}))

export const deleteItem = (items, item) => {

    items = [...items || []]
    const index = items.findIndex(e => e.Id === item.Id)

    if (index !== -1) {

        items.splice(index, 1)

    }

    return items

}

export const findTextInObject = (obj, searchString, keys) => {

    if (!keys) {

        keys = Object.keys(obj)

    }

    searchString = searchString.toLowerCase()

    for (let i = 0; i < keys.length; i++) {

        const key = keys[i]
        const value = obj[key]

        if (typeof value === 'string' && value.toLowerCase().indexOf(searchString) !== -1) {

            return true

        }

    }

    return false

}

export const getBooleanValue = e => e === null ? false : e

export const getDateValue = e => e === null ? '' : e

export const getFullName = (e, qm) => e ? (e.lastName || e.LastName || e.firstName || e.FirstName ? ((e.lastName || e.LastName || '') + ' ' + (e.firstName || e.FirstName || '')) : (qm ? '???' : '')) : (qm ? '???' : '')

export const getGenders = () => genders

export const getMonths = () => months

export const getNumberValue = e => e === null ? '' : e

export const getPersonRequestStatus = personRequestStatusId => personRequestStatuses.find(e => e.value === personRequestStatusId)

export const getPersonRequestStatuses = () => personRequestStatuses

export const getPlayerLicenseRequestStatus = playerLicenseRequestStatusId => playerLicenseRequestStatuses.find(e => e.value === playerLicenseRequestStatusId)

export const getPlayerLicenseRequestStatuses = () => playerLicenseRequestStatuses

export const getPlayerLicenseStatus = playerLicenseStatusId => playerLicenseStatuses.find(e => e.value === playerLicenseStatusId)

export const getPlayerLicenseStatuses = () => playerLicenseStatuses

export const getSelectValue = e => e ? e.value : ''

export const getSides = () => sides

export const getStatusLabel = (label, icon, text, solvedBy, solvedDate) => {

    if (solvedBy || solvedDate) {

        return (

            <OverlayTrigger overlay={

                <Tooltip>

                    {solvedBy && getFullName(solvedBy)}

                    {solvedBy && solvedDate && <br/>}

                    {solvedDate && formatDateTime(solvedDate)}

                </Tooltip>

            }>

                <span className={'text-' + text}><Icon icon={icon}/> {label}</span>

            </OverlayTrigger>

        )

    }

    return (

        <span className={'text-' + text}><Icon icon={icon}/> {label}</span>

    )

}

export const getStringValue = e => e === null ? '' : e

export const getTransferRequestStatus = transferRequestStatusId => transferRequestStatuses.find(e => e.value === transferRequestStatusId)

export const getTransferRequestStatuses = () => transferRequestStatuses

export const isDevelopment = () => process.env.NODE_ENV === 'development'

export const isEmpty = e => e === undefined || e === null

export const isFemale = e => e && e.Gender && e.Gender.value === GENDER_FEMALE

export const splitDate = date => {

    const parts = typeof date === 'string' ? date.split('-') : []
    let year = Number(parts[0])
    let month = Number(parts[1])
    let day = Number(parts[2])

    if (isNaN(year) || year === 0) {
        year = ''
    }
    if (isNaN(month) || month === 0) {
        month = ''
    }
    if (isNaN(day) || day === 0) {
        day = ''
    }

    return {year, month, day}

}

export const splitRange = range => {

    const parts = typeof range === 'string' ? range.split('-') : []
    let from = Number(parts[0])
    let to = Number(parts[1])

    if (isNaN(from) || from === 0) {
        from = ''
    }
    if (isNaN(to) || to === 0) {
        to = ''
    }

    return {from, to}

}

export const updateItem = (items, item) => {

    items = [...items || []]
    const index = items.findIndex(e => e.Id === item.Id)

    if (index === -1) {

        items.push(item)

    } else {

        items[index] = item

    }

    return items

}

export const parseHexColor = (hexColor) => {
    const hexWithoutHash = hexColor.substring(1)
    const r = parseInt(hexWithoutHash.substring(0, 2), 16);
    const g = parseInt(hexWithoutHash.substring(2, 4), 16);
    const b = parseInt(hexWithoutHash.substring(4, 6), 16);

    return {r, g, b};
}

export const rgbToHex = (r, g, b) => {
    const toHex = (value) => {
        const hex = value.toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
    };

    const hexR = toHex(r);
    const hexG = toHex(g);
    const hexB = toHex(b);

    return `#${hexR}${hexG}${hexB}`;
}

export const isNum = (value) => typeof value === 'number' || (typeof value === 'string' && !isNaN(parseFloat(value)))