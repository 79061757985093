import React from 'react';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { translate as _ } from '@morawadigital/skynet-framework';

const TabButton = (props) => {
    return (
        <Nav.Item role='button'>
            {!props.disabled ? (
                <Nav.Link eventKey={props.eventKey}>{props.label}</Nav.Link>
            ) : (
                <OverlayTrigger overlay={<Tooltip>{_('Speichern zum Bearbeiten')}</Tooltip>}>
          <span className='cursor-not-allowed'>
            <Nav.Link eventKey={props.eventKey} disabled>
              {props.label}
            </Nav.Link>
          </span>
                </OverlayTrigger>
            )}
        </Nav.Item>
    );
};

export default TabButton;
