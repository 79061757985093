// FIELD TYPES
export const FIELD_TYPE_ADDRESS                                         = 'address'
export const FIELD_TYPE_AUTOCOMPLETE                                    = 'autocomplete'
export const FIELD_TYPE_DATE                                            = 'date'
export const FIELD_TYPE_FLEX_DATE                                       = 'flexDate'
export const FIELD_TYPE_MULTI_TEXT                                      = 'multiText'
export const FIELD_TYPE_NUMBER                                          = 'number'
export const FIELD_TYPE_RANGE                                           = 'range'
export const FIELD_TYPE_SELECT                                          = 'select'
export const FIELD_TYPE_TEXTAREA                                        = 'textarea'
export const FIELD_TYPE_TEXTFIELD                                       = 'text'
export const FIELD_TYPE_TOGGLE                                          = 'toggle'

export const FIELD_TYPE_COLOR                                          = 'color'

// GENDERS
export const GENDER_MALE                                                = 0
export const GENDER_FEMALE                                              = 1

// MODULES
export const MODULE_ACCOUNTING                                          = 'accounting'
export const MODULE_COMPETITION_MANAGEMENT                              = 'competitionManagement'
export const MODULE_LICENSING                                           = 'licensing'

// PERSON REQUEST STATUSES
export const PERSON_REQUEST_STATUS_DRAFT                                = -1
export const PERSON_REQUEST_STATUS_PENDING                              = 0
export const PERSON_REQUEST_STATUS_ACCEPTED                             = 1
export const PERSON_REQUEST_STATUS_REJECTED                             = 2

// PLAYER LICENSE REQUEST STATUSES
export const PLAYER_LICENSE_REQUEST_STATUS_PENDING                      = 0
export const PLAYER_LICENSE_REQUEST_STATUS_ACCEPTED                     = 1
export const PLAYER_LICENSE_REQUEST_STATUS_REJECTED                     = 2

// PLAYER LICENSE STATUSES
export const PLAYER_LICENSE_STATUS_VALID                                = 0
export const PLAYER_LICENSE_STATUS_DELETED                              = 1
export const PLAYER_LICENSE_STATUS_WITHDRAWN                            = 3
export const PLAYER_LICENSE_STATUS_EXPIRED                              = 4

// PRIVILEGES
export const PRIV_LOAD_AGEGROUPS                                        = '31e097a9-a1b0-4932-8849-7d7cf583fa42'
export const PRIV_LOAD_CLUBS                                            = '177bc293-1d65-493e-9e42-c4f9b7908ae4'
export const PRIV_LOAD_FEDERATIONS                                      = '0b3167d8-96de-4adb-b97b-ec71d1a1d975'
export const PRIV_LOAD_NATIONS                                          = '49264e8d-08a2-482a-a5ce-58f1ff350d10'
export const PRIV_LOAD_PERSONS                                          = 'c36179c2-8ec5-4a62-86cb-dc4151dd5248'
export const PRIV_LOAD_PERSON_REQUESTS                                  = '0EA3821D-7671-4DF3-9FC6-EC5510F59B2A'
export const PRIV_LOAD_PLAYER_LICENSE_REQUESTS                          = '73241295-A060-4F1B-9EBD-FD123C37C9B9'
export const PRIV_LOAD_TRANSFER_REQUESTS                                = '7769EFB3-ED32-4049-8320-90083D180A2D'
export const PRIV_LOAD_SEASON_LEAGUES                                   = '7769EFB3-ED32-4049-8320-90083D180A2D'
export const PRIV_LOAD_LEAGUES                                          = '7769EFB3-ED32-4049-8320-90083D180A2D'

// REQUEST TYPES
export const REQUEST_TYPE_PERSON                                        = 'person'
export const REQUEST_TYPE_PLAYER_LICENSE                                = 'playerLicense'
export const REQUEST_TYPE_TRANSFER                                      = 'transfer'

// TRANSFER REQUEST STATUSES
export const TRANSFER_REQUEST_STATUS_PENDING                            = 0
export const TRANSFER_REQUEST_STATUS_ACCEPTED                           = 1
export const TRANSFER_REQUEST_STATUS_REJECTED                           = 2
export const TRANSFER_REQUEST_STATUS_CANCELED                           = 3

// SIDES
export const SIDE_LEFT                                                  = 0
export const SIDE_RIGHT                                                 = 1

// UploadFile
export const UPLOAD_FILE_STATUS_ERROR     = 'UPLOAD_FILE_STATUS_ERROR'
export const UPLOAD_FILE_STATUS_SUCCESS   = 'UPLOAD_FILE_STATUS_SUCCESS'
export const UPLOAD_FILE_STATUS_UPLOADING = 'UPLOAD_FILE_STATUS_UPLOADING'