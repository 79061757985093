import React from 'react';
import {Link} from 'react-router-dom';
import {Card, Col, Placeholder, Row} from 'react-bootstrap';
import ItemOptions from '../elements/ItemOptions';

function Item(props) {
    const renderName = () => {
        return props.url ? (
            <Link to={props.url} onClick={props.onClick}>
                {props.name}
            </Link>
        ) : (
            props.name
        );
    };

    return (
        <Card className='mb-1'>
            <Card.Body>
                <Row className='align-items-center'>
                    <Col className='fs-6' {...(props.colProps || {})}>
                        {props.item ? (
                            props.options ? (
                                <Row className='align-items-center flex-nowrap'>
                                    <Col xs='auto'>
                                        <ItemOptions className='d-inline-block me-3'>
                                            {props.options}
                                        </ItemOptions>
                                    </Col>
                                    <Col>{renderName()}</Col>
                                </Row>
                            ) : (
                                renderName()
                            )
                        ) : (
                            <Placeholder animation='wave'>
                                <Placeholder
                                    xs={props.index === 0 ? 9 : props.index === 1 ? 4 : 7}
                                    bg='primary'
                                />
                            </Placeholder>
                        )}
                    </Col>
                    {props.children}
                </Row>
                {props.footer}
            </Card.Body>
        </Card>
    );
}

export default Item;