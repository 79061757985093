import React, {useState, useEffect} from 'react';
import {ajx, ajxs, formatDate, Icon, translate as _} from '@morawadigital/skynet-framework';
import {Button, Col, Form, Modal, Row, Table} from 'react-bootstrap';
import {createOptions, getDateValue, getSelectValue, getStringValue, isNum, parseHexColor, rgbToHex} from '../../util';
import {getFieldsExercise} from "../pages/Event/fields";
import useUnsavedChanges from "../../hooks/use-unsaved-changes";
import AthleteItem from "../items/Athlete";

const EventExercises = (props) => {
    const [athlete, setAthlete] = useState({})
    const [hasUnsavedChanges, setHasUnsavedChanges] = useUnsavedChanges();
    const fieldDefinition = getFieldsExercise()
    const [saving, setSaving] = useState(false)
    const [validated, setValidated] = useState(false)
    const [valueOptions, setValueOptions] = useState({})
    const [loading, setLoading] = useState(true)
    const [searchQuery, setSearchQuery] = useState('');

    const load = () => {
        const items = [
            {
                options: {method: 'POST'},
                success: (e) => setValueOptions({
                    ...valueOptions,
                    athletes: createOptions(e),
                }),
                url: 'api/Athlete/Get',
            },
        ];

        ajxs({}, items)
    }

    useEffect(() => {
        load()
    }, [])

    const save = e => {
        e.preventDefault()

        if (!e.currentTarget.checkValidity()) {
            setValidated(true)
            return;
        }

        setSaving(true)
        setValidated(true)


        const data = {
            eventId: athlete.EventId,
            athleteId: getSelectValue(athlete.Exercises),
            multiplier: athlete.Multiplier,
        }

        const success = () => {
            props.onHide()
        }

        ajx({
            complete: () => setSaving(false),
            single: true,
            url: 'api/Team/AddOrEdit',
            data,
            success,
        })
    }


    const renderTitle = () => {
        if (props.isNew) {
            return _('New Athlete')
        } else if (athlete && athlete.Name) {
            return athlete.Name;
        } else {
            return <Icon icon='spinner' spin/>;
        }
    }


    const onChangeFieldHandler = (e, f) => {
        setAthlete((prevComp) => ({
            ...prevComp,
            [e]: f,
        }))
        setHasUnsavedChanges(f !== null)
    }

    const formDisabled = !loading || saving

    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredAthletes = props.athletes.filter((athlete) => {
        const {Firstname, Lastname, Birthday} = athlete;
        const lowerSearchQuery = searchQuery.toLowerCase();

        console.log(athlete)
        return (
            Firstname.toLowerCase().includes(lowerSearchQuery) ||
            Lastname.toLowerCase().includes(lowerSearchQuery) ||
            formatDate(Birthday).toLowerCase().includes(lowerSearchQuery)
        );
    });

    return (
        <Modal show={props.onShow} onHide={props.onHide} backdrop='static' size='lg'>
            <Form onSubmit={save} noValidate validated={validated}>
                <fieldset disabled={saving}>
                    <Modal.Header closeButton>
                        <Modal.Title>{renderTitle()}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Control
                            className="mb-4"
                            onChange={handleSearchQueryChange}
                            placeholder={_('Athlete filtern...')}
                            value={searchQuery}
                        />
                        <div style={{height: '60rem', overflow: 'auto', paddingRight: 10}}>
                            {filteredAthletes.length ? (
                                filteredAthletes.map((athlete, index) => (
                                    <AthleteItem key={index} item={athlete}/>
                                ))
                            ) : (
                                <div className='my-3 text-center'>{_('Keine Einträge gefunden.')}</div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant='secondary' onClick={props.onHide}>
                            {_('Abbrechen')}
                        </Button>
                        <Button type='submit'>{_('Speichern')}</Button>
                    </Modal.Footer>
                </fieldset>
            </Form>
        </Modal>
    )
}

export default EventExercises;
