import {Icon, translate as _, ajx} from '@morawadigital/skynet-framework'
import React from 'react'
import {Button, Card, Col, ProgressBar, Row} from 'react-bootstrap'
import {UPLOAD_FILE_STATUS_ERROR, UPLOAD_FILE_STATUS_SUCCESS, UPLOAD_FILE_STATUS_UPLOADING} from '../../util/constants'
import {getFileIcon, getReadableFileSize} from '../../util/file'

class UploadFile extends React.Component {

    #timer

    constructor(props) {

        super(props)

        this.state = {

            progress: 0,
            uploadStatus: null,

        }

        this.#timer = null

    }

    componentDidMount() {

        this.upload()

    }

    componentWillUnmount() {

        this.clearTimer()

    }

    clearTimer() {

        if (this.#timer) {

            clearInterval(this.#timer)

            this.#timer = null

        }

    }

    upload() {

        this.#timer = setInterval(() => this.updateProgress(), 100)

        this.setState({progress: 20, uploadStatus: UPLOAD_FILE_STATUS_UPLOADING}, () => {

            ajx({

                complete: () => this.clearTimer(),
                data: {files: {__arr: true, values: [this.props.file]}},
                error: () => this.setState({uploadStatus: UPLOAD_FILE_STATUS_ERROR}),
                success: () => this.setState({progress: 100}, () => setTimeout(() => this.setState({uploadStatus: UPLOAD_FILE_STATUS_SUCCESS}), 1000)),
                url: 'api/Import/importFile',

            })

        })

    }

    updateProgress() {

        this.setState({progress: 100 - ((100 - this.state.progress) * .95)})

    }

    render() {

        return (

            <Card className='m-1 upload-file'>

                <Card.Body>

                    <Row className='align-items-center'>

                        <Col xs='auto' className='file-icon'>{getFileIcon(this.props.file.type)}</Col>

                        <Col>

                            <Row>

                                <Col className='fs-5'>{this.props.file.name}</Col>
                                <Col className='text-end'>{getReadableFileSize(this.props.file.size)}</Col>

                            </Row>

                            <Row>

                                <Col className='upload-status'>

                                    {

                                        this.state.uploadStatus === UPLOAD_FILE_STATUS_SUCCESS ?

                                            <div className='text-success'><Icon icon='check'/> {_('Upload erfolgreich')}
                                            </div>

                                            : this.state.uploadStatus === UPLOAD_FILE_STATUS_ERROR ?

                                                <div className='text-danger'>

                                                    <span className='me-3'><Icon icon='exclamation-triangle'/> {_('Fehler')}</span>

                                                    <Button variant='secondary' onClick={() => this.upload()}
                                                            size='sm'>{_('Nochmal versuchen')}</Button>

                                                </div>

                                                : this.state.uploadStatus === UPLOAD_FILE_STATUS_UPLOADING ?

                                                    <ProgressBar now={this.state.progress} striped animated/>

                                                    :

                                                    ''

                                    }

                                </Col>

                            </Row>

                        </Col>

                    </Row>

                </Card.Body>

            </Card>

        )

    }

}

export default UploadFile