import React from 'react';
import {Dropdown} from 'react-bootstrap';
import {Icon} from '@morawadigital/skynet-framework';

function ItemOptions({children}) {
    return (
        <Dropdown>
            <Dropdown.Toggle className='dropdown-toggle-ellipsis'>
                <Icon icon='ellipsis-vertical'/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {children}
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default ItemOptions;
