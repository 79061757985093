import {createOptions} from "../util";

const useApiOptions = (props) => {
    const {
        setValueOptions,
    } = props

    const generateApiOptions = (optionsArray) => {
        const apiOptions = [];

        optionsArray.map((option) => {
            console.log(option.method)
            const requestOptions = {
                options: { method: option.method || 'GET' },
                success: (e) => {
                    setValueOptions((prevOptions) => ({
                        ...prevOptions,
                        [option.key]: createOptions(e),
                    }));
                },
                ...(option.data ? { data: option.data } : {}),
                url: option.url,
            };

            apiOptions.push(requestOptions);
        });

        return apiOptions;
    }

    return [generateApiOptions]
}

export default useApiOptions



