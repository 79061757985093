import React, {useCallback, useEffect, useState} from "react";
import ExerciseItem from "../items/Exercise";
import {Button} from "react-bootstrap";
import {ajx, ajxs, Icon, translate as _} from "@morawadigital/skynet-framework";
import ExerciseModal from "../modals/EventExercises";

const EventExercises = (props) => {
    const [exercises, setExercises] = useState(null)
    const [exerciseDeleting, setExerciseDeleting] = useState(null)
    const [exerciseEditing, setExerciseEditing] = useState(null)
    const [show, setShow] = useState(false)

    const createExercise = (exercise) => {
        const createdExercise = {
            Owners: null,
            Id: 0,
            LosId: '',
            new: false,
            ...(exercise || {}),
        };

        return createdExercise;
    }

    const load = useCallback(() => {

        const items = [{
            options: {method: 'POST'},
            success: e => setExercises(e),
            url: 'api/Exercise/Get'
        }]

        ajxs({ }, items)
    },[])

    useEffect(() => {
        load()
    }, [show])

    const openExercise = (e) => {
        setExerciseEditing(createExercise(e))
        setShow(true)
    }

    const deleteExercise = (exercise) => {
        ajx({
            data: {id: exercise.Id},
            url: 'api/Exercise/Delete',
            success: () => {
                setExerciseDeleting(null)
                props.onDelete(exercise)
            },
        })
    }

    return (
        <>
            <Button onClick={() => openExercise({new: true})}><Icon icon='plus'/> {_('Neu')}</Button>

            <div className='mt-3'>
                {exercises && exercises.length ?
                    exercises.map((exercise, index) => (
                        <ExerciseItem
                            key={index}
                            item={exercise}
                            onDelete={deleteExercise}
                            onEdit={(e) => openExercise(e)}
                        />
                    )) :
                    <div className='my-3 text-center'>{_('Keine Einträge gefunden.')}</div>
                }

                {show &&
                    <ExerciseModal
                        exercise={exercises}
                        load={load}
                        onShow={show}
                        onHide={() => {
                            setShow(false)
                            setExerciseEditing(null)
                        }}
                        fedId={props.fedId}
                        seasonLeagueId={props.seasonLeagueId}
                        isNew={exerciseEditing?.new}
                        slId={props.slId}
                        id={exerciseEditing?.Id}
                        losLeagues={props.losLeagues}
                    />
                }
            </div>
        </>
    )
}

export default EventExercises;
