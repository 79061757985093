import {translate as _} from '@morawadigital/skynet-framework'
import {
  FIELD_TYPE_AUTOCOMPLETE,
  FIELD_TYPE_DATE,
  FIELD_TYPE_NUMBER,
  FIELD_TYPE_TEXTFIELD
} from '../../../util/constants'

const fields = [
  {
    object: 'Event',
    property: 'Name',
    label: _('Name'),
    isRequired: true,
    __type: FIELD_TYPE_TEXTFIELD
  },
  {
    object: 'Event',
    property: 'StartDate',
    label: _('Start Date'),
    isRequired: true,
    __type: FIELD_TYPE_DATE
  },
  {
    object: 'Event',
    property: 'EndDate',
    label: _('End Date'),
    isRequired: true,
    __type: FIELD_TYPE_DATE
  },
  {
    object: 'Event',
    property: 'DailyStart',
    label: _('Daily Start'),
    __type: FIELD_TYPE_DATE
  },
  {
    object: 'Event',
    property: 'DailyEnd',
    label: _('Daily End'),
    __type: FIELD_TYPE_DATE
  }
]

const fieldsExercise = [
  {
    object: 'Event',
    property: 'Exercises',
    label: _('Exercises'),
    isRequired: true,
    __type: FIELD_TYPE_AUTOCOMPLETE,
    __optionsKey: 'exercises',
  },
  {
    object: 'Event',
    property: 'Multiplier',
    label: _('Multiplier'),
    isRequired: true,
    __type: FIELD_TYPE_NUMBER
  },
]

export const getFields = () => fields

export const getFieldsExercise = () => fieldsExercise