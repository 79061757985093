import {getLanguage} from '@morawadigital/skynet-framework'

export const getLabel = field => {
    if (!field.label) {
        return field.__label || field.property || field.name
    }

    try {
        const label = JSON.parse(field.label)
        if (label) {
            const lang = getLanguage()

            if (lang in label) {
                return label[lang]
            } else if (lang !== 'en' && ('en' in label)) {
                return label['en']
            } else {
                const keys = Object.keys(label)

                if (keys.length) {
                    return label[keys[0]]
                }
            }
        }
    } catch (e) {
    }

    return field.label
}