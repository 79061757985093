import React from 'react';
import {useHistory} from 'react-router-dom';
import {formatDate, translate as _} from '@morawadigital/skynet-framework';
import {Dropdown} from 'react-bootstrap';
import Item from './Item';

function Athlete(props) {

    console.log(props.item)
    const history = useHistory();
    const {item, index, onDelete} = props;
    const url = item && `${_('/exercise/')}${item.Id}`;

    const renderOptions = (url) => {
        return (
            <>
                <Dropdown.Item as='button' onClick={() => history.push(url)}>
                    {_('Bearbeiten')}
                </Dropdown.Item>
                <Dropdown.Item as='button' onClick={() => onDelete(item)}>
                    {_('Löschen')}
                </Dropdown.Item>
            </>
        )
    }


    const title = <>
        <span className="d-block flex-1">{item?.Firstname} {item.Lastname} ({item?.Position?.Name})</span>
        <span className="d-block flex-1 fs-sm text-black">{ item?.Birthday ? formatDate( item.Birthday ) : '' } {' - Vienna Vikings'}</span>
    </>

    return (
        <Item
            index={index}
            item={item}
            name={item && title}
            // options={renderOptions(url)}
            url={url}
        />
    );
}

export default Athlete;
