import React from 'react'
import {Icon, translate as _} from '@morawadigital/skynet-framework'
import ItemsPage from "./../ItemsPage";
import Exercise from "../../items/Exercise";
import {Link} from "react-router-dom";

function Exercises(props) {

  const renderSubheaderBlock = () => {
    return (
      <Link to={_('/exercise/neu')} className='btn btn-primary'><Icon icon='plus'/> {_('Neu')}</Link>
    )
  }

  return (
    <ItemsPage
      dataMethod='POST'
      ItemComponent={Exercise}
      pageIcon='stopwatch-20'
      pageTitle={_('Exercise')}
      getDataUrl='api/Exercise/Get'
      renderSubheaderBlock={renderSubheaderBlock}
      {...props}
    />
  )
}

export default Exercises